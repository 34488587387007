import React, { Component } from "react";
import Header from "../Header/index";
import ActionCard from "./ActionCard/index";

class Main extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div id="main">
        <Header setOpen={() => this.props.setOpen()} {...this.props} />
        <div className="main_center_text">
          <h1
            style={{
              marginTop: 60,
            }}
          >
            Creating Opportunities.
          </h1>
          <h1>Simplifying Recruitment.</h1>
        </div>
        <ActionCard {...this.props} />
      </div>
    );
  }
}

export default Main;
