import { useEffect, useState } from "react";
import FormDialog from "../Landing/Dialog";
import Footer from "../Landing/Footer";
import ButtonAppBar from "../Landing/Header";
import ConstructionIcon from "@mui/icons-material/Construction";

export default function PrivacyPage() {
  const [open, setOpen] = useState(false);
  const utmParam = window.location.search;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <FormDialog
        open={open}
        setOpen={() => setOpen(false)}
        utmParam={utmParam}
      />
      <ButtonAppBar setOpen={() => setOpen(true)} />
      <div
        style={{
          height: "67vh",
          display: "flex",
          alignItems: "center",
        }}
      >
        <span
          style={{
            margin: "auto",
            fontSize: "1.3em",
            fontWeight: "bolder",
            textAlign: "center",
          }}
        >
          <ConstructionIcon
            style={{
              fontSize: "2em",
            }}
          />
          <br />
          Page is under construction.
        </span>
      </div>
      <Footer setOpen={() => setOpen(true)} utmParam={utmParam} />
    </div>
  );
}
