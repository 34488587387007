import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import React from "react";

import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import { useForm, ValidationError } from "@formspree/react";

export default function FormDialog(props) {
  const handleClose = () => {
    props.setOpen(false);
  };

  const [state, handleSubmit] = useForm("xayvwone");
  if (state.succeeded) {
    return (
      <Dialog style={{ minWidth: 300 }} open={props.open} onClose={handleClose}>
        <DialogTitle>Thanks for joining!</DialogTitle>
        <DialogActions style={{ margin: "0 auto" }}>
          <Button
            style={{
              color: "#FFF",
              background: "#FFA800",
              padding: "5px 30px",
            }}
            size="small"
            variant="contained"
            type="submit"
            onClick={() => handleClose()}
          >
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>CONNECT WITH US</DialogTitle>
          <DialogContent style={{ width: 400 }}>
            <FormControl component="fieldset">
              <FormLabel component="legend">I AM</FormLabel>
              <RadioGroup
                aria-label="gender"
                defaultValue="female"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label="EMPLOYER"
                />

                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label="COLLEGE/UNIVERSITY PLACEMENT TEAM"
                />
              </RadioGroup>
            </FormControl>
            <TextField
              margin="dense"
              id="name"
              name="name"
              label="Name"
              fullWidth
              variant="standard"
            />
            <ValidationError prefix="name" field="name" errors={state.errors} />
            <TextField
              margin="dense"
              label="Email ID"
              id="email"
              type="email"
              name="email"
              fullWidth
              variant="standard"
            />
            <TextField
              margin="dense"
              label="CONTACT NUM"
              id="phNumber"
              name="phNumber"
              type="number"
              fullWidth
              variant="standard"
            />
            <TextField
              margin="dense"
              label="ORGANIZATION NAME"
              id="organization_name"
              name="organization_name"
              fullWidth
              variant="standard"
            />
            <TextField
              margin="dense"
              label="MESSAGE"
              id="message"
              name="message"
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions style={{ margin: "0 auto" }}>
            <Button
              style={{
                color: "#FFF",
                background: "#FFA800",
                padding: "10px 30px",
              }}
              variant="contained"
              type="submit"
            >
              SUBMIT
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
