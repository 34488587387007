import React from "react";
import serviceStyles from "../styles";
import "./index.css";

export default function ServiceBenifit() {
  const classes = serviceStyles();
  return (
    <div className="serviceBenifit">
      <div id="benefit_head">
        <h1>Benefits Of VIPApplicant</h1>
        {/* <p>
          VIPApplicant engages candidates from the very first touch-point and
          identifies the best job-fit candidates for successful hiring and low
          attrition rates.
        </p> */}
      </div>
      <div className={classes.benefitDivContainer}>
        {[
          {
            title: "Minimise Attrition",
            subTitle: `VIPApplicant engages candidates from the
            very first touch-point and identifies the best job-fit candidates
            for successful hiring and low attrition rates.`,
          },
          {
            title: "Data Insights",
            subTitle: `
The platform provides granular insights and additional information to make sure that every hiring decision is correct and successful.
`,
          },
          {
            title: "Standardisation",
            subTitle: `
The platform intuitively brings a structure to hiring, which makes the lives of recruiters far  easier.`,
          },
          {
            title: "Quick Turnaround",
            subTitle: `
The AI-powered chatbot, asynchronous video interviews and our internal evaluation systems improve efficiencies and provides faster hiring.
`,
          },
        ].map((a, idx) => (
          <span className="service_card" key={idx}>
            <h3>{a.title}</h3>
            {a.subTitle}
            <span></span>
          </span>
        ))}
      </div>
    </div>
  );
}
