import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import Logo from "../../../assets/VIPAPPLICANT.png";
import { useNavigate } from "react-router";
const Footer = (props) => {
  const navigate = useNavigate();
  return (
    <div id="footer">
      <div className="footer_container">
        <Grid spacing={3} container>
          <Grid md={12} lg={4} item>
            <img
              alt="Logo missing"
              height={60}
              width={100}
              src={Logo}
              style={{
                cursor: "pointer",
              }}
              onClick={(e) => {
                navigate("/");
              }}
            />
          </Grid>
          <Grid md={12} lg={4} item>
            <ul>
              <li className={"cursor"} onClick={(e) => navigate("/employer")}>
                Employer
              </li>
              <li className={"cursor"}>
                <a target="_blank" href="https://vipapplicant.com/blog/">
                  Blogs
                </a>
              </li>
              <li onClick={() => props.setOpen()} className={"cursor"}>
                Contact Us
              </li>
            </ul>
          </Grid>
          <Grid md={12} lg={4} item>
            <ul>
              <li
                onClick={(e) => navigate("/terms_condition")}
                className={"cursor"}
              >
                Privacy Policy
              </li>
              <li
                onClick={(e) => navigate("/privacy_policy")}
                className={"cursor"}
              >
                Terms & Conditions
              </li>
            </ul>
          </Grid>
        </Grid>
        <div
          style={{
            borderTop: "1px solid #ccc",
            padding: "30px 5px",
            textAlign: "center",
            marginTop: 20,
          }}
        >
          ©{new Date().getFullYear()} - Kimyo Learning Pvt. Ltd. All Rights
          Reserved.
        </div>
      </div>
    </div>
  );
};

export default Footer;
