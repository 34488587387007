import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import { useLocation, useNavigate } from "react-router";
import Logo from "../../../assets/VIPAPPLICANT.png";
import { COLORS } from "../../../constants/color";
import MenuComponent from "../../MenuComponent/Index";
import Drawer from "./Drawer";

export default function ButtonAppBar(props) {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Drawer open={open} setOpen={() => setOpen(false)} />
      <AppBar
        style={{
          background: "#fff",
          boxShadow: "none",
          position: "fixed",
          zIndex: 120,
        }}
        position="static"
        id="header"
      >
        <Toolbar>
          <img
            src={Logo}
            alt="logo"
            height={40}
            width={20}
            style={{
              cursor: "pointer",
            }}
            onClick={(e) => navigate("/")}
          />
          <div className="nav_links">
            <MenuComponent
              btnText={"Services"}
              // eslint-disable-next-line no-sparse-arrays
              menuListWithAction={[
                {
                  action: () => navigate("/construction"),
                  text: "Candidates",
                },
                {
                  action: () => navigate("/construction"),
                  text: "College",
                },
                ,
                {
                  action: () => navigate("/employer"),
                  text: "Employer",
                },
              ]}
              style={{
                color: COLORS.primary_text_color,
                marginRight: 20,
              }}
            />
            {/* <Button
              style={{
                color: COLORS.primary_text_color,
                marginRight: 20,
              }}
              onClick={(e) => {
                navigate("/services");
              }}
            >
              SERVICES
            </Button> */}
            <a target="_blank" href="https://vipapplicant.com/blog/">
              <Button
                style={{ color: COLORS.primary_text_color, marginRight: 20 }}
              >
                BLOGS
              </Button>
            </a>

            <Button
              onClick={() => props.setOpen()}
              style={{ color: COLORS.primary_text_color, marginRight: 20 }}
            >
              CONTACT US
            </Button>
            {location.pathname !== "/services" ? (
              <a href={`https://rb.vipapplicant.com/?${props.utmParam}`}>
                <Button
                  variant="contained"
                  style={{
                    color: "#fff",
                    background: COLORS.primary,
                    fontWeight: "bold",
                  }}
                >
                  Optimise My Resume
                  <ArrowRightAltIcon />
                </Button>
              </a>
            ) : (
              <Button
                variant="contained"
                onClick={() => props.setOpen()}
                style={{
                  color: "#fff",
                  background: COLORS.primary,
                  fontWeight: "bold",
                }}
              >
                Connect With Us
                <ArrowRightAltIcon />
              </Button>
            )}
          </div>
          <div className="nav_links_mobile">
            <IconButton
              onClick={() => setOpen(true)}
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
            >
              <MenuIcon
                className="menu_icon"
                color="#000"
                style={{ color: "#000" }}
              />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
