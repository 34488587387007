import React, { useEffect, lazy, Suspense } from "react";
import "./App.css";
import Main from "./components/Landing/Main";
import { Routes, Route, Navigate } from "react-router-dom";
import GoogleLogo from "./assets/googleLogo.png";
import GuideBookBG from "./assets/guidebook-bg.webp";

import { Element } from "react-scroll";
import { Button } from "@mui/material";
import { COLORS } from "./constants/color";
import { Download } from "@mui/icons-material";
import AlertDialog from "./components/Landing/Dialog/DialogComponent";
import useUnload from "./hooks/useUnload";
import ReportsPage from "./components/Services/services.page";
import ConstructionPage from "./components/Construction/Index";
import TermsPage from "./components/TermsAndPolicy/terms";
import PrivacyPage from "./components/TermsAndPolicy/privacy";

const NotFoundPage = lazy(() => import("./components/404.page"));

const AnlyticSection = lazy(() =>
  import("./components/Landing/analyticsSection/index")
);
const CatalysingSection = lazy(() =>
  import("./components/Landing/catalysingSection")
);
const AiSection = lazy(() => import("./components/Landing/aiSection/index"));
const EmployerSection = lazy(() =>
  import("./components/Landing/employerSubtitleSection/index")
);
const BlogSection = lazy(() => import("./components/Landing/newsSection"));

const Footer = lazy(() => import("./components/Landing/Footer/index"));
const Dialog = lazy(() => import("./components/Landing/Dialog"));
const Reviews = lazy(() => import("./components/Landing/Review"));

function App() {
  const [open, setOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const utmParam = window.location.search;
  // useEffect(() => {
  //   setTimeout(() => {
  //     setAlertOpen(true);
  //   }, 5000);
  // }, []);
  // useUnload((e) => {
  //   e.preventDefault();
  //   e.returnValue = "";
  // });
  return (
    <div className="App">
      <AlertDialog handleClose={() => setAlertOpen(false)} open={alertOpen} />
      <Suspense fallback={() => <p>Loading ...</p>}>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Dialog
                  open={open}
                  setOpen={() => setOpen(false)}
                  utmParam={utmParam}
                />
                <Element name="main" id="first-section">
                  <Main setOpen={() => setOpen(true)} utmParam={utmParam} />
                  <a href="https://g.co/kgs/af4nYN">
                    <div
                      // name="review_section"
                      id="google-review"
                    >
                      <img
                        src={GoogleLogo}
                        alt="not-found"
                        width={40}
                        height={40}
                        style={{
                          height: 40,
                          margin: "auto",
                        }}
                      />
                      <p
                        style={{
                          fontWeight: "bolder",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            margin: "auto",
                            maxWidth: "60%",
                          }}
                        >
                          <span className="fa fa-star checked"></span>
                          <span className="fa fa-star checked"></span>
                          <span className="fa fa-star checked"></span>
                          <span className="fa fa-star checked"></span>
                          <span className="fa fa-star half-checked"></span>
                        </div>
                        <p />
                        Customer Ratings{"   "}
                        <span
                          style={{
                            color: "#0c2C65",
                            fontWeight: "bolder",
                            fontSize: 22,
                          }}
                        >
                          4.7
                        </span>
                      </p>
                      <span>
                        The Highest Rated AI-Powered Resume Builder and
                        Optimiser service in India.
                      </span>
                    </div>
                  </a>
                </Element>

                <Element name="anlytics_section">
                  <AnlyticSection utmParam={utmParam} />
                </Element>
                <Element name="catalysing_section">
                  <CatalysingSection
                    setOpen={() => setOpen(true)}
                    utmParam={utmParam}
                  />
                </Element>
                <Element
                  style={{
                    padding: 30,
                  }}
                >
                  <div
                    className="container3"
                    id="guideBook"
                    style={{
                      backgroundImage: `url(${GuideBookBG})`,
                    }}
                  >
                    <h1
                      style={{
                        maxWidth: "50%",
                      }}
                    >
                      <span className="primary_color">
                        Download Free Resume Guidebook to Craft the Best Version
                        of Yourself.
                      </span>
                    </h1>
                    <Button
                      href={"https://rb.vipapplicant.com"}
                      component="a"
                      variant="contained"
                      style={{ color: "#000", background: COLORS.primary }}
                      size="small"
                    >
                      Download GuideBook
                      <Download
                        style={{
                          marginLeft: 10,
                        }}
                      />
                    </Button>
                  </div>
                </Element>
                <Element name="ai_section">
                  <AiSection utmParam={utmParam} />
                </Element>
                <Element name="employer_section">
                  <EmployerSection
                    setOpen={() => setOpen(true)}
                    utmParam={utmParam}
                  />
                </Element>
                <Element
                  name="review_section"
                  style={{
                    background: "#00B67A",
                    padding: "3.5rem 2rem",
                    display: "flex",
                  }}
                >
                  <Reviews utmParam={utmParam} />
                </Element>
                <Element name="blog_section">
                  <BlogSection
                    setOpen={() => setOpen(true)}
                    utmParam={utmParam}
                  />
                </Element>

                <Footer setOpen={() => setOpen(true)} utmParam={utmParam} />
              </>
            }
          />
          <Route path="/404" element={<NotFoundPage />} />
          <Route path="/employer" element={<ReportsPage />} />
          <Route path="/construction" element={<ConstructionPage />} />
          <Route path="/terms_condition" element={<ConstructionPage />} />
          <Route path="/privacy_policy" element={<ConstructionPage />} />
          <Route path="*" element={<Navigate replace to="/404" />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
