import { Button, Icon } from "@mui/material";
import { useEffect, useState } from "react";
import C1 from "../../assets/service_icon1.png";
import C2 from "../../assets/service_icon2.png";
import C3 from "../../assets/service_icon3.png";
import { COLORS } from "../../constants/color";
import FormDialog from "../Landing/Dialog";
import Footer from "../Landing/Footer";
import ButtonAppBar from "../Landing/Header";
import ServiceBenifit from "./Benifits/Index";
import InterviewSection from "./interviewSection/Index";
import serviceStyles from "./styles";

export default function ReportsPage() {
  const classes = serviceStyles();
  const [open, setOpen] = useState(false);
  const utmParam = window.location.search;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <FormDialog
        open={open}
        setOpen={() => setOpen(false)}
        utmParam={utmParam}
      />
      <ButtonAppBar setOpen={() => setOpen(true)} />
      {/* Section 1  */}
      <div className={classes.firstSection}>
        <div className="contentContainer">
          <h1
            style={{
              fontWeight: 100,
              fontSize: "2.8rem",
            }}
          >
            {" "}
            Hire{" "}
            <p
              style={{
                padding: 0,
                margin: 0,
                fontWeight: 900,
                textDecoration: "underline",
              }}
            >
              Right Talent
            </p>{" "}
            Faster!
          </h1>
          <Button
            onClick={() => setOpen(true)}
            variant="contained"
            size="large"
            style={{
              color: "#fff",
              background: COLORS.primary,
              borderRadius: "50px",
              width: "100%",
              fontWeight: "bold",
            }}
          >
            Connect With Us
          </Button>
        </div>
      </div>

      {/* Section 2  */}
      <div className={classes.secondSection}>
        <section className="partI">
          <h1>Providing Recruitment Solutions With The Power of AI</h1>
          <p>
            VIPApplicant empowers recruiters with structured and granular data
            to identify the most suitable talent and hire them effortlessly.
            Move faster and get an edge over your competitors with our
            AI-powered automated shortlisting process.
          </p>
        </section>
        <section className="partII">
          {/* <p>SMOOTH OPERATION</p> */}
          <h1>Enabling Efficient Intelligent Candidate Discovery</h1>

          <div className="card">
            {[
              {
                icon: C1,
                title: "Sourcing",
                subTitle:
                  "Select the best resumes from the widest network to build a robust recruitment funnel.",
              },

              {
                icon: C2,
                title: "Standardization",
                subTitle:
                  "Get concise and standard information to compare the resumes in a glance and identify the best candidates.",
              },
              {
                icon: C3,
                title: "Holistic Evaluation",
                subTitle:
                  "The Resume Evaluation Engine can be customised to assess candidates on their skills and behaviours for an integrated evaluation.",
              },
            ].map((e, idx) => {
              return (
                <p className={classes.card} key={idx}>
                  <Icon
                    style={{
                      fontSize: "60px",
                    }}
                  >
                    <img
                      style={{
                        filter: "invert(1)",
                      }}
                      src={e.icon}
                      height="100%"
                      width="100%"
                      alt="not found"
                    />
                  </Icon>
                  <h1>{e.title}</h1>
                  <span>{e.subTitle}</span>
                </p>
              );
            })}
          </div>
        </section>
      </div>

      {/* Section 3  */}
      <div>
        <InterviewSection setOpen={() => setOpen(true)} />
      </div>

      {/* Section 4  */}
      <div>
        <ServiceBenifit />
      </div>
      <Footer setOpen={() => setOpen(true)} utmParam={utmParam} />
    </div>
  );
}
