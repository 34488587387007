import React, { Component } from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

import { COLORS } from "../../../../constants/color";
import ActionIcon_avif from "../../../../assets/resume.avif";
import ActionIcon_jpg from "../../../../assets/resume.jpg";

class index extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Card id="main_card">
        <picture>
          <source type="image/avif" srcset={ActionIcon_avif} />
          <img
            width={20}
            height={"100%"}
            src={ActionIcon_jpg}
            className="main_card_img"
            alt="cv_icon"
          />
        </picture>
        <p>Resume Builder</p>
        <a href={`https://rb.vipapplicant.com/${this.props.utmParam}`}>
          <Button
            variant="contained"
            style={{ color: "#fff", background: COLORS.primary }}
          >
            Optimise My Resume <ArrowRightAltIcon />
          </Button>
        </a>
        <div
          style={{
            margin: "20px 0px",
          }}
        >
          Upload or Create Your Resume to Craft an ATS-friendly, AI-Powered
          Resume.
        </div>
      </Card>
    );
  }
}

export default index;
