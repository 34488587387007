import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import Logo from "../../../assets/VIPAPPLICANT.png";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { COLORS } from "../../../constants/color";
import { scroller } from "react-scroll";
import { useLocation, useNavigate } from "react-router";

export default function SwipeableTemporaryDrawer(props) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: true,
  });

  const location = useLocation();
  const navigate = useNavigate();

  // const scrollToElement = (element) => {
  //   scroller.scrollTo(element, {
  //     duration: 1500,
  //     delay: 100,
  //     smooth: true,
  //   });
  // };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <img
        src={Logo}
        alt="logo"
        width={20}
        height={40}
        style={{ width: "70%", padding: 20 }}
      />
      <List>
        <ListItem button>
          <ListItemText primary={"Services"} />
        </ListItem>
        <ListItem
          onClick={() => navigate("/employer")}
          button
          sx={{
            ml: 3,
          }}
        >
          <ListItemText primary={"Employer"} />
        </ListItem>
        <ListItem
          onClick={() => navigate("/construction")}
          button
          sx={{
            ml: 3,
          }}
        >
          <ListItemText primary={"College"} />
        </ListItem>
        <ListItem
          onClick={() => navigate("/construction")}
          button
          sx={{
            ml: 3,
          }}
        >
          <ListItemText primary={"Candidates"} />
        </ListItem>

        <a target="_blank" href="https://vipapplicant.com/blog/">
          <ListItem button>
            <ListItemText primary={"Blogs"} />
          </ListItem>
        </a>
        <ListItem button>
          <ListItemText primary={"Contact Us"} />
        </ListItem>
        <ListItem button>
          {location.pathname !== "/services" ? (
            <a href={`https://rb.vipapplicant.com/?${props.utmParam}`}>
              <Button
                variant="contained"
                style={{
                  color: "#fff",
                  background: COLORS.primary,
                  fontWeight: "bold",
                  fontSize: "0.75rem",
                }}
              >
                Optimise My Resume
                <ArrowRightAltIcon />
              </Button>
            </a>
          ) : (
            <Button
              variant="contained"
              onClick={() => props.setOpen()}
              style={{
                color: "#fff",
                background: COLORS.primary,
                fontWeight: "bold",
              }}
            >
              Connect With Us
              <ArrowRightAltIcon />
            </Button>
          )}
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <SwipeableDrawer
        anchor="right"
        open={props.open}
        onClose={() => props.setOpen()}
      >
        {list("left")}
      </SwipeableDrawer>
    </div>
  );
}
