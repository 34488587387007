import { makeStyles } from "@mui/styles";
import HireServiceImg from "../../../assets/service_hire.jpeg";
import ServiceHandImg from "../../../assets/service_hand.png";

const serviceStyles = makeStyles((theme) => ({
  main: {},
  firstSection: {
    height: "100vh",
    backgroundImage: `url(${HireServiceImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",

    "& .contentContainer": {
      margin: "auto",
      position: "absolute",
      padding: "3rem 4rem",
      bottom: 100,
      width: "30%",
      background: "#0000008f",
      borderRadius: "0px 10px 10px 0px",
      "& h1": {
        color: "#FFA800",
        textAlign: "center",
        fontWeight: "700",
        fontSize: "3rem",
        [theme.breakpoints.down("md")]: {
          fontSize: "2rem",
        },
      },
      [theme.breakpoints.down("md")]: {
        padding: "1rem",
        width: "80%",
      },
    },
  },
  secondSection: {
    // height: "100vh",
    display: "flex",
    flexDirection: "column",
    "& .partI": {
      flex: "0.2",
      textAlign: "center",
      fontSize: "large",
      background: "#FFA800",
      padding: "50px 10rem",
      color: "#fff",
      "& p": {
        margin: "auto",
        width: "55%",
        [theme.breakpoints.down("md")]: {
          width: "100% !important",
        },
      },
      [theme.breakpoints.down("md")]: {
        // width: "100%",
        padding: "1rem",
      },
    },
    "& .partII": {
      flex: "0.8",
      background: "#0C2C65",
      backgroundImage: `url(${ServiceHandImg})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      textAlign: "center",
      padding: "4rem",
      color: "white",
      backgroundPosition: "center center",
      "& .card": {
        display: "flex",
        justifyContent: "space-around",
        [theme.breakpoints.down("md")]: {
          flexDirection: "column",
        },
      },
      [theme.breakpoints.down("md")]: {
        // width: "100%",
        padding: "1rem",
      },
    },
  },
  card: {
    width: "20%",
    background: "rgba(255, 255, 255, 0.15)",
    boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    padding: "30px",
    [theme.breakpoints.down("md")]: {
      width: "inherit",
      fontSize: "small",
    },
  },
  benefitDivContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignContent: "flex-start",
    height: "52vh",
    gap: 10,
    [theme.breakpoints.down("md")]: {
      height: "85%",
    },
  },
}));

export default serviceStyles;
