import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Component } from "react";

import Employee_jpg from "../../../assets/employee1.jpg";
import Employee_avif from "../../../assets/service_dynamic.jpeg";
import Employee_avif3 from "../../../assets/service_video.png";
// import Employee2_avif from "../../../assets/service_interview.png";
import Employee2_avif from "../../../assets/service_chatbot.jpeg";

class InterviewSection extends Component {
  render() {
    return (
      <div
        id="employer_section"
        style={{
          background: "#fff",
        }}
      >
        <div className="container2">
          {/* <h2>For Recruiter</h2> */}
          {/* <h1
            style={{
              fontSize: 13,
              marginTop: "20px",
            }}
          >
            OUR VISION
          </h1> */}
          <h1
            style={{
              marginTop: "30px",
            }}
          >
            <span className=" span-h1">What we do?</span>
          </h1>
          <h1
            style={{
              fontSize: 24,
              fontWeight: "bold",
            }}
          >
            <span className="primary_color">
              Evaluate, Connect and Assessment
            </span>
          </h1>

          <Grid
            className="inner_container_1 reverse_col"
            container
            alignItems={"center"}
          >
            <Grid className="inner_container_1_left" item lg={6}>
              <div
                className="inner_container_2_left_div"
                style={{
                  margin: "auto",
                }}
              >
                <div className="service_para">
                  <h2>DYNAMIC EVALUATION</h2>
                  <p>
                    The Resume Evaluation Engine is dynamic in nature and allows
                    for customisation of parameters for unique client
                    requirements, and provides objective candidate resume score
                    out of 100, even before you see them!
                  </p>
                  <p>
                    With a pre-screened and ranked list of top candidates, you
                    do not have to spend time on each resume to identify the
                    most qualified ones. This accelerated shortlisting process
                    gets you ahead in your game and hires faster with a better
                    job-fit.
                  </p>
                </div>

                <Button
                  onClick={() => this.props.setOpen()}
                  style={{
                    background:
                      "linear-gradient(92.28deg, #f17400 3.44%, #ffa800 95.82%)",
                  }}
                  variant="contained"
                  className="card_button2"
                >
                  Ask For Demo
                  <ArrowRightAltIcon />
                </Button>
              </div>
            </Grid>
            <Grid className="inner_container_1_right" item lg={6}>
              <picture>
                <source type="image/avif" srcset={Employee_avif} />
                <img
                  width={"100%"}
                  height={"100%"}
                  src={Employee_jpg}
                  alt="College Placement"
                />
              </picture>
            </Grid>
          </Grid>
        </div>

        <div className="container3" id="college_section">
          {/* <h3>FOR COLLEGE PLACEMENT TEAMS</h3> */}

          <Grid className="inner_container_1 " container alignItems={"center"}>
            <Grid className="inner_container_1_right" item lg={6}>
              <picture>
                <source type="image/avif" srcset={Employee2_avif} />
                <img
                  width={20}
                  height="100%"
                  src={Employee2_avif}
                  alt="College Placement"
                />
              </picture>
            </Grid>
            <Grid className="inner_container_1_left" item lg={6}>
              <div
                className="inner_container_2_left_div"
                style={{ width: "100%" }}
              >
                <div className="service_para">
                  <h2>CHATBOT</h2>
                  <p>
                    Our AI-enabled chatbot screens the candidates by automating
                    the first-level, non-negotiable questions.
                    <p />
                    The chatbot is customisable and asks questions related to
                    skills, location, notice period, salary expectations, etc.
                    while screening the candidates.
                    <p />
                    With your requirements already fulfilled, there are no
                    surprises later in the hiring process. You can directly call
                    the candidates for interviews without conducting the
                    preliminary phone screening.
                  </p>
                  <Button
                    onClick={() => this.props.setOpen()}
                    style={{
                      background:
                        "linear-gradient(92.28deg, #f17400 3.44%, #ffa800 95.82%)",
                    }}
                    variant="contained"
                    className="card_button2"
                  >
                    Ask For Demo
                    <ArrowRightAltIcon />
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="container2">
          {/* <h2>For Recruiter</h2> */}
          <Grid
            className="inner_container_1 reverse_col"
            container
            alignItems={"center"}
          >
            <Grid className="inner_container_1_left" item lg={6}>
              <div
                className="inner_container_2_left_div"
                style={{
                  width: "100%",
                }}
              >
                <div className="service_para">
                  <h2>ASYNCHRONOUS INTERVIEW</h2>
                  <p>
                    Our platform allows you to standardise the recruitment
                    process and administer the first round of video interviews
                    without the hassle of blocking multiple calendars.
                    <p />
                    It makes your hiring process robust and efficient as you
                    already know the candidates before they step into the
                    interview room.
                  </p>
                </div>
                <Button
                  onClick={() => this.props.setOpen()}
                  style={{
                    background:
                      "linear-gradient(92.28deg, #f17400 3.44%, #ffa800 95.82%)",
                  }}
                  variant="contained"
                  className="card_button2"
                >
                  Ask For Demo
                  <ArrowRightAltIcon />
                </Button>
              </div>
              <p
                style={{
                  margin: "20px 0p",
                }}
              />
            </Grid>
            <Grid className="inner_container_1_right" item lg={6}>
              <picture>
                <source type="image/avif" srcset={Employee_avif3} />
                <img
                  width={20}
                  height={"100%"}
                  src={Employee_avif3}
                  alt="College Placement"
                />
              </picture>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default InterviewSection;
